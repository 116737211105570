/**
 * All of the CSS for your public-facing functionality should be
 * included in this file.
 */
 
#webcodeno_wc_insoft_search_order {
    display: flex;
    input {
        height: 2.6375rem;
    }
}

#webcodeno_wc_insoft_order_info {
    display: none;
    border: 1px solid rgba(0, 0, 0, 0.05);
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
}